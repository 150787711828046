import dayjs from "dayjs";

export const compareObject = (a = {}, b = {}) => {
  if (a === b) return true;
  if ((Array.isArray(a) && !Array.isArray(b)) || (!Array.isArray(a) && Array.isArray(b))) return false;
  const aggregate = { ...a, ...b };
  for (const key in aggregate) {
    if (typeof a[key] === "object" && typeof b[key] === "object") {
      return compareObject(a[key], b[key]);
    }
    if (a[key] !== b[key]) return false;
  }
  return true;
};

/**
 *
 * @param {Record<string,any>} data
 * @returns {Record<string,any>}
 */
export const trimObjectProperties = (data = {}) => {
  const newData = { ...data };
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (typeof element === "string") {
        newData[key] = element.trim();
      }
    }
  }
  return newData;
};

export const isObjectEmpty = objectName => {
  return Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

export const isArrayEmpty = array => {
  return array.length === 0;
};

export const isValidType = (name, allowType) => {
  for (let j = 0; j < allowType.length; j++) {
    let sCurExtension = allowType[j];
    if (
      name.substr(name.length - sCurExtension.length, sCurExtension.length).toLowerCase() ===
      sCurExtension.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const validateFiles = (fileArray, allowFileType) => {
  return fileArray.filter(file => isValidType(file.name, allowFileType));
};

export const hasScrollBar = className => {
  const element = document.querySelector(className);
  if (!element) {
    return false;
  }
  const hasScrollBar =
    element.clientHeight < element.scrollHeight || element.clientWidth < element.scrollWidth;

  return hasScrollBar;
};

export const normalizeData = data => {
  const normalizedData = {
    entities: {},
    result: [],
  };

  data.forEach(item => {
    const { id } = item;
    normalizedData.entities[id] = item;
    normalizedData.result.push(id);
  });

  return normalizedData;
};

export const formatDate = (dateString) =>{
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
}

export const getFileTypeClassName = (fileType) => {
  if (!fileType) {
    return ''; // Return a default class or an empty string if file is not provided
  }

  const fileTypeParam = fileType.split('/')[0]; // 'image', 'application', etc.
  const fileFormat = fileType.split('/')[1];

  switch (fileTypeParam) {
    case 'image':
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.gif':
    case '.bmp':
    case '.webp':
    case '.svg':
    case '.ico':
      return 'icon-image-file';
    case 'application':
      if(fileFormat=="pdf"){
        return 'icon-pdf-file';
      }else if(fileFormat=="x-zip-compressed"){
        return 'icon-archive-file';
      }else if(fileFormat=="vnd.openxmlformats-officedocument.wordprocessingml.document"){
        return 'icon-doc-file';
      }else if(fileFormat=="vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
        return 'icon-xls-file';
      }else{
        return 'icon-unsupported-file';
      }
    case '.pdf':
      return 'icon-pdf-file';
    case '.zip':
      return 'icon-archive-file';
    case '.doc':
    case '.docx':
      return 'icon-doc-file';
    case '.xls':
    case '.xlsx':
      return 'icon-xls-file';
    default:
      return 'icon-unsupported-file'; // Return a default class for unknown file types
  }
};

// Function to determine if a color is dark
export const isDark = (color) => {
  if (color.startsWith('#')) {
    color = color.substring(1); // Remove the leading #
    // Convert hexadecimal to RGB
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    // Calculate brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  } else if (color.startsWith('rgb(')) {
    // Extract RGB values from RGB format
    const rgb = color.match(/\d+/g).map(Number);
    // Calculate brightness
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness < 128;
  } else {
    // Unsupported color format
    throw new Error('Unsupported color format');
  }
}

export const  getMimeTypeFromExtension = (extension) => {
  switch (extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
          return 'image/jpeg';
      case 'png':
          return 'image/png';
      case 'gif':
          return 'image/gif';
      case 'pdf':
          return 'application/pdf';
      case 'csv':
          return 'text/csv';
      case 'json':
          return 'application/json';
      // Add more cases for other file types as needed
      default:
          return 'application/octet-stream'; // Default to generic binary data
  }
}

export const objectToQueryString = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
}