/** Check local auth exist */
export const issetAuthToken = async() => {
  return (sessionStorage.getItem('accessToken'))? true : false;
}

/** Set data */
export const setAuthToken  = async(data) =>{
  sessionStorage.setItem('accessToken', data);
  return true;
}

/** Set expiresOn */
export const setExpiresOn  = async(data) =>{
  sessionStorage.setItem('expiresOn', data);
  return true;
}

/** Get expiresOn */
export const getExpiresOn = () => {
  const expiresOn = sessionStorage.getItem('expiresOn');
  if (expiresOn) {
    return Number(expiresOn);
  }
  return null; // Or handle this case appropriately
};

/** Get data */
export function getAuthToken(){
  if (issetAuthToken) {
      const storageData = sessionStorage.getItem('accessToken');
      return storageData;
  } else {
      console.log('Cannot get from storage: Session storage no set!');
      return '';
  }
}

/** Flush the session after logout */
export const flushAuthToken = async() => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('expiresOn');
  return false;
}
