import api from "./api";
import graphAPI from "./graphAPI";
import customerAPI from "./customerAPI";
import { API } from "../constant/service";
import { objectToQueryString } from "../utils/common";

/**
 *
 * @param {Record<string, any>} params
 * @returns {Promise<{
 *  data: {Record<string, any>}
 * }>}
 */
export const getLogin = (...props) => api.POST(API.GET_LOGIN, ...props);
export const logout = (...props) => api.POST(API.LOGOUT, ...props);

/** KANBAN BOARD */
export const getAllBoard = params => api.GET(API.GET_ALL_BOARD, params);
export const getBoardById = params => api.GET(API.GET_BOARD_BY_ID+'/'+params);
export const createBoard = (params) => api.POST(API.CREATE_BOARD, params);
export const updateBoard = (params) => api.PUT(API.UPDATE_BOARD+'/'+params.boardId, params);
export const deleteBoard = (params) => api.POST(API.DELETE_BOARD+'/'+params);
export const getBoardStatusMovement = (params)=> api.GET(API.BOARD_STATUS_MOVEMENT, params);
export const getTicketByBoardLabel = (params)=> api.GET(API.GET_TICKET_BY_BOARD_LABEL+'?'+objectToQueryString(params));
export const getFilteredBoardData = (params)=> api.POST(API.GET_FILTERED_BOARD_DATA, params);

/** KANBAN BOARD - STATUS */
export const createBoardStatus = (params) => api.POST(API.CREATE_BOARD_STATUS, params);
export const updateBoardStatus = (params) => api.PUT(API.UPDATE_BOARD_STATUS+'/'+params.labelId, params);
export const deleteBoardStatus = (params) => api.POST(API.DELETE_BOARD_STATUS+'/'+params);

/** MASTER */
export const getColor = params => api.GET(API.GET_COLOR, params);
export const addColor = params => api.POST(API.ADD_COLOR, params);
export const updateColor = params => api.PUT(API.UPDATE_COLOR+'/'+params.color_id, params);
export const deleteColor = params => api.DELETE(API.DELETE_COLOR+'/'+params.color_id);
export const getCountry = params => api.GET(API.GET_COUNTRY, params);
export const addCountry = params => api.POST(API.ADD_COUNTRY, params);
export const updateCountry = params => api.PUT(API.UPDATE_COUNTRY+'/'+params.country_id, params);
export const deleteCountry = params => api.DELETE(API.DELETE_COUNTRY+'/'+params.country_id);
export const getUserDepartment = params => api.GET(API.GET_USER_DEPARTMENT, params);
export const getDepartment = params => api.GET(API.GET_DEPARTMENT, params);
export const addDepartment = (params) => api.POST(API.ADD_DEPARTMENT, params);
export const updateDepartment = (params) => api.PUT(API.UPDATE_DEPARTMENT+'/'+params.dept_id, params);
export const deleteDepartment = (params) => api.DELETE(API.DELETE_DEPARTMENT+'/'+params.dept_id);
export const getDesignation = params => api.GET(API.GET_DESIGNATION, params);
export const getRoles = params => api.GET(API.GET_ROLES, params);
export const getMarketRegion = params => api.GET(API.GET_MARKET_REGION, params);
export const getRegion = params => api.GET(API.GET_REGION, params);
export const addRegion = params => api.POST(API.ADD_REGION, params);
export const updateRegion = params => api.PUT(API.UPDATE_REGION+'/'+params.region_id, params);
export const deleteRegion = params => api.DELETE(API.DELETE_REGION+'/'+params.region_id);
export const getLanguage = params => api.GET(API.GET_LANGUAGE, params);
export const addLanguage = params => api.POST(API.ADD_LANGUAGE, params);
export const updateLanguage = params => api.PUT(API.UPDATE_LANGUAGE+'/'+params.language_id, params);
export const deleteLanguage = params => api.DELETE(API.DELETE_LANGUAGE+'/'+params.language_id);

export const getShift = params => api.GET(API.GET_SHIFT, params);
export const getRoleSetting = params => api.GET(API.GET_ROLE_SETTING, params);
export const updateRoleSetting = params => api.POST(API.UPDATE_ROLE_SETTING, params);
export const getIndustry = params => customerAPI.GET(API.GET_INDUSTRY, params);
export const getCustomerRegion = params => customerAPI.GET(API.GET_CUSTOMER_REGION, params);
export const getCustomerType = params => customerAPI.GET(API.GET_CUSTOMER_TYPE, params);
export const getPackage = params => customerAPI.GET(API.GET_PACKAGE, params);
export const getStatusCodeMaster = ()=> api.GET(API.STATUS_CODE_MASTER);
export const getFontFamily = ()=> api.GET(API.GET_FONT_FAMILY_MASTER);
export const addFontFamily = (params)=> api.POST(API.ADD_FONT_FAMILY_MASTER, params);
export const updateFontFamily = (params)=> api.PUT(API.UPDATE_FONT_FAMILY_MASTER+'/'+params.font_id, params);
export const deleteFontFamily = (params)=> api.DELETE(API.DELETE_FONT_FAMILY_MASTER+'/'+params.font_id);

export const getLabels = (params) => api.GET(API.GET_LABELS, params);
export const addLabel = (params)=> api.POST(API.ADD_LABEL, params);
export const updateLabel = (params) => api.PUT(API.UPDATE_LABEL+'/'+params.labelId, params);
export const getCustomerLanguage = params => customerAPI.GET(API.GET_CUSTOMER_LANGUAGE, params);
export const getPrimaryMarket = () => customerAPI.GET(API.GET_PRIMARY_MARKET);

/**** Tools Master Data ***/
export const getProductTools = params => api.GET(API.GET_PRODUCT_TOOLS, params);
export const getPlatforms =()=> api.GET(API.PLATFORMS_LIST);
export const getSubscriptionsList =()=> api.GET(API.SUBSCRIPTIONS_TYPES);
export const getToolStatus =()=> api.GET(API.TOOL_STATUS);
export const getCategories =()=> api.GET(API.CATEGORIES_LIST);
export const getProductOwners =()=> api.GET(API.PRODUCT_OWNERS);
export const getCurrency = (params) => api.GET(API.GET_CURRENCY, params);
export const addCurrency = (params) => api.POST(API.ADD_CURRENCY, params);
export const updateCurrency = (params) => api.PUT(API.UPDATE_CURRENCY+'/'+params.currency_id, params);
export const deleteCurrency = (params) => api.DELETE(API.DELETE_CURRENCY+'/'+params.currency_id);

/** USER PROFILE */
// export const getUserProfile = (...props) => graphAPI.GET(API.GET_USER_PROFILE, ...props);
export const getUserInfo = (params) => api.GET(API.GET_USER_INFO, params);
export const getUserDetailsById = (params) => api.GET(API.GET_USER_DETAILS_BY_ID+'/'+params);
export const getAllMembers = (params) => api.GET(API.GET_ALL_MEMBERS, params);

/*** My Team **/
export const getMyTeamMembers = (params) => api.GET(API.GET_MY_TEAM_MEMBER);
export const getMyTeamMemberProfile = (params) => api.GET(API.GET_MY_TEAM_MEMBER_PROFILE+params);
export const updateMyTeamMemberProfile =(params)=> api.POST(API.UPDATE_MY_TEAM_MEMBER_PROFILE, params);
export const getAllADMembers =(params)=> api.GET(API.GET_AD_MEMBERS);
export const addTeamMembers =(params)=> api.POST(API.ADD_TEAM_MEMBERS,params);

/*** MAIL SENT **/
export const noBoardAccessRequest =()=> api.GET(API.NO_BOARD_ACCESS_REQUEST);
export const newUserAccessRequest =()=> api.GET(API.NEW_USER_ACCESS_REQUEST);

/** CUSTOMER RELATED INFORMATION */
export const getCustomers = params => customerAPI.POST(API.GET_CUSTOMERS, params);
export const getCustomerOverview =(params)=> customerAPI.GET(API.CUSTOMER_OVERVIEW+params);
export const getCustomerOrdersInvoices =(params)=> customerAPI.GET(API.CUSTOMER_ORDERS_INVOICES+params);
export const getCustomerServicesLinks = (params)=> customerAPI.GET(API.CUSTOMER_SERVICES_LINKS+params);
export const getCustomerLatestOrderId= (params)=> customerAPI.GET(API.CUSTOMER_LATEST_ORDER_ID+'?'+params);
export const updateCustomerOrderId= (params)=> api.POST(API.UPDATE_CUSTOMER_ORDER_ID, params);

/**** Tools Management ***/
export const addTools = (params)=> api.POST(API.ADD_TOOLS, params);
export const updateTools = (params) => api.POST(API.UPDATE_TOOLS, params);
export const editorViewTool = (params) => api.GET(API.EDITOR_VIEW_TOOL+params);
export const toolsList = (params) => api.POST(API.TOOLS_LIST, params);
export const deleteTool = (params) => api.GET(API.DELETE_TOOL+params);

/** TASK MANAGEMENT */
export const addTicket = (params)=> api.POST(API.ADD_TICKET, params);
export const updateTicket = (params)=> api.POST(API.UPDATE_TICKET+'/'+params.ticket_id,params);
export const suggestedMembers = (params)=> api.GET(API.SUGGESTED_MEMBERS, params);
export const customerSearch = (params)=> customerAPI.POST(API.CUSTOMER_SEARCH+params);
export const getTicketDetails = (params)=> api.GET(API.GET_TICKET_DETAILS+'/'+params);
export const updateTicketSalesData = (params)=> api.POST(API.UPDATE_TICKET_SALES_DATA,params);
export const updateTicketCommonData = (params)=> api.POST(API.UPDATE_TICKET_COMMON_DATA,params);
export const getPackageToolDetails = (params)=> api.GET(API.GET_PACKAGE_TOOL_DETAILS,params);
export const ticketFileUpload = (params)=> api.FILEUPLOAD(API.TICKET_FILE_UPLOAD,params);
export const deleteTicketAttachment = (params)=> api.POST(API.DELETE_TICKET_ATTACHMENT,params);
export const downloadAttachment = (params)=> api.FILEDOWNLOAD(API.FILE_DOWNLOAD+'?filepath='+params.file_upload_path, params);

/** TOOL TICKET */
export const updateToolTicket = (params)=> api.POST(API.UPDATE_TOOL_TICKET,params);
export const toolFileUpload = (params)=> api.FILEUPLOAD(API.TOOL_FILE_UPLOAD,params);
export const moveTicket = (params)=> api.POST(API.MOVE_TICKET,params);
export const getCommentDetails = (params)=> api.POST(API.GET_COMMENT_DETAILS,params);
export const addUpdateComment = (params)=> api.FILEUPLOAD(API.ADD_UPDATE_COMMENT,params);
export const deleteComment = (params)=> api.POST(API.DELETE_COMMENT,params);
export const deleteToolAttachment = (params)=> api.POST(API.DELETE_TOOL_ATTACHMENT,params);
export const addAssigneeStatus = (params)=> api.POST(API.ADD_ASSIGNEE_STATUS,params);
export const dataDependencyAdd = (params)=> api.POST(API.DATA_DEPENDENCY_ADD,params);
export const dataDependencyStatus = (params)=> api.POST(API.DATA_DEPENDENCY_STATUS,params);

/** SUBSCRIPTION/PACKAGE */
export const getAllPackages = (params)=> api.GET(API.GET_ALL_PACKAGES,params);
export const addPackage = (params)=> api.POST(API.ADD_PACKAGE,params);
export const updatePackage = (params)=> api.POST(API.UPDATE_PACKAGE,params);
export const deletePackage = (params)=> api.GET(API.DELETE_PACKAGE+params);

/** NOTIFICATION */
export const getRecentNotification = (params)=> api.POST(API.GET_RECENT_NOTIFICATION,params);
export const updateNotification = (params)=> api.POST(API.UPDATE_NOTIFICATION,params);