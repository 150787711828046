export const API = {
  GET_LOGIN: "/api/auth/login",
  LOGOUT: "/api/auth/logout",
  GET_USER_PROFILE: "/v1.0/me",
  GET_USER_INFO: "/api/Login/userinfo",
  GET_USER_DETAILS_BY_ID: "/api/Login/userdetail",

  /** KANBAN */
  GET_ALL_BOARD: "/api/BoardManagement/boards",
  GET_BOARD_BY_ID: "/api/BoardManagement/boards",
  CREATE_BOARD: "/api/BoardManagement/add",
  UPDATE_BOARD: "/api/BoardManagement/update",
  DELETE_BOARD: "/api/BoardManagement/delete",
  CREATE_BOARD_STATUS: "/api/BoardStatus/add",
  UPDATE_BOARD_STATUS: "/api/BoardStatus/update",
  DELETE_BOARD_STATUS: "/api/BoardStatus/delete",
  BOARD_STATUS_MOVEMENT: "api/BoardStatus/getboardstatusmovement",
  GET_TICKET_BY_BOARD_LABEL: "/api/BoardManagement/ticketsbyboardlabel",
  GET_FILTERED_BOARD_DATA: "/api/BoardManagement/boardfilter",

  /** MASTER */
  GET_COLOR: "/api/CoreColor/corecolors",
  ADD_COLOR: "/api/CoreColor/add",
  UPDATE_COLOR: "/api/CoreColor/update",
  DELETE_COLOR: "/api/CoreColor/delete",
  GET_COUNTRY: "/api/Country/getallcountry",
  ADD_COUNTRY: "/api/Country/add",
  UPDATE_COUNTRY: "/api/Country/update",
  DELETE_COUNTRY: "/api/Country/delete",
  GET_USER_DEPARTMENT: "/api/DepartmentManagement/userdepartments",
  GET_DEPARTMENT: "/api/DepartmentManagement/departments",
  ADD_DEPARTMENT: "/api/DepartmentManagement/add",
  UPDATE_DEPARTMENT: "/api/DepartmentManagement/update",
  DELETE_DEPARTMENT: "/api/DepartmentManagement/delete",
  GET_DESIGNATION: "/api/Designation/designations",
  GET_ROLES: "/api/Role/roles",
  GET_MARKET_REGION: "/api/Region/marketregions",
  GET_REGION: "/api/Region/regions",
  ADD_REGION: "/api/Region/add",
  UPDATE_REGION: "/api/Region/update",
  DELETE_REGION: "/api/Region/delete",
  GET_LANGUAGE: "/api/Language/languages",
  ADD_LANGUAGE: "/api/Language/add",
  UPDATE_LANGUAGE: "/api/Language/update",
  DELETE_LANGUAGE: "/api/Language/delete",
  GET_SHIFT: "/api/Shift/shifts",
  GET_INDUSTRY: "/api/IndustryManagement/industries",
  GET_CUSTOMER_REGION: "/api/MarketManagement/markets",
  GET_CUSTOMER_TYPE: "/api/TypeManagement/customertypes",
  GET_PACKAGE: "/api/PackageManagement/categories",
  STATUS_CODE_MASTER:"/api/StatusCodeMaster/statuscodemaster",
  GET_FONT_FAMILY_MASTER:"/api/FontFamily/fonts",
  ADD_FONT_FAMILY_MASTER:"/api/FontFamily/add",
  UPDATE_FONT_FAMILY_MASTER:"/api/FontFamily/update",
  DELETE_FONT_FAMILY_MASTER:"/api/FontFamily/delete",
  GET_LABELS: "/api/Label/labels",
  ADD_LABEL: "/api/Label/add",
  UPDATE_LABEL: "/api/Label/update",
  GET_CUSTOMER_LANGUAGE: "/api/Role/customerlanguage",
  GET_PRIMARY_MARKET: "/api/MarketManagement/markets",
  
  /**** TOOLS MASTER DATA ***/
  GET_PRODUCT_TOOLS: "/api/Tool/tools",
  PLATFORMS_LIST: "/api/Tool/platforms",
  SUBSCRIPTIONS_TYPES: "/api/Tool/subscriptions",
  TOOL_STATUS: "/api/Tool/status",
  CATEGORIES_LIST: "/api/Tool/categories",
  PRODUCT_OWNERS: "/api/Tool/productowners",
  GET_CURRENCY: "api/Currency/currency",
  ADD_CURRENCY: "api/Currency/add",
  UPDATE_CURRENCY: "api/Currency/update",
  DELETE_CURRENCY: "api/Currency/delete",

  /*** My Team **/
  GET_MY_TEAM_MEMBER: "/api/User/userteams",
  GET_MY_TEAM_MEMBER_PROFILE: "/api/User/editorviewuserteam/id?id=",
  UPDATE_MY_TEAM_MEMBER_PROFILE: "/api/User/updateuserteam",
  GET_ALL_MEMBERS: "/api/User/registeredusers",

  /**** AD Users****/
  ADD_TEAM_MEMBERS: "/api/AdManagement/add-ad-users",
  GET_AD_MEMBERS: "/api/AdManagement/ad-users",

  /** SETTINGS */
  GET_ROLE_SETTING: "/api/Role/roleSetting",
  UPDATE_ROLE_SETTING: "/api/Role/updateRoleSetting",

  /*** MAIL SENT **/
  NO_BOARD_ACCESS_REQUEST: "/api/MailManagement/board-access-request",
  NEW_USER_ACCESS_REQUEST: "/api/MailManagement/access-request",

  /** CUSTOMER */
  GET_CUSTOMERS: "/api/CustomerManagement/customers",
  CUSTOMER_OVERVIEW: "/api/CustomerManagement/customers/id?customerid=",
  CUSTOMER_ORDERS_INVOICES: "/api/CustomerManagement/orders/id?customerid=",
  CUSTOMER_SERVICES_LINKS: "/api/CustomerManagement/servicelink/id?customerid=",
  
  /**** TOOLS MANAGEMENT ***/
  ADD_TOOLS : '/api/ToolManagement/add-tools',
  UPDATE_TOOLS : '/api/ToolManagement/update-tools',
  EDITOR_VIEW_TOOL : '/api/ToolManagement/editorviewtool/id?id=',
  TOOLS_LIST: '/api/ToolManagement/listorgridviewtool',
  DELETE_TOOL: '/api/ToolManagement/deletetool?id=',

  /** TASK/TICKET MANAGEMENT */
  ADD_TICKET: '/api/TicketManagement/add-ticket',
  UPDATE_TICKET: '/api/TicketManagement/update-ticket',
  SUGGESTED_MEMBERS: '/api/TicketManagement/suggested-members',
  CUSTOMER_SEARCH: '/api/TicketManagement/ticketcustomers?customersearch=',
  GET_TICKET_DETAILS: '/api/TicketManagement/ticket-details',
  UPDATE_TICKET_SALES_DATA: '/api/TicketManagement/add-update-ticket-detail',
  UPDATE_TICKET_COMMON_DATA: '/api/TicketManagement/add-update-common-data',
  GET_PACKAGE_TOOL_DETAILS: '/api/TicketManagement/package-tool-details',
  TICKET_FILE_UPLOAD: '/api/TicketManagement/single-file-upload',
  DELETE_TICKET_ATTACHMENT: '/api/TicketManagement/deleteticketattachmentdetail',
  FILE_DOWNLOAD: 'api/TicketManagement/filedownload',
  CUSTOMER_LATEST_ORDER_ID: 'api/TicketManagement/customerlatestorderid',
  UPDATE_CUSTOMER_ORDER_ID: 'api/TicketManagement/updatecustomerorderid',

  /** TOOL TICKET MANAGEMENT */
  UPDATE_TOOL_TICKET: '/api/TicketToolManagement/upserttoolticket',
  TOOL_FILE_UPLOAD: '/api/TicketToolManagement/attachments',
  MOVE_TICKET: '/api/TicketToolManagement/moveticket',
  GET_COMMENT_DETAILS: '/api/TicketToolManagement/ticketcommentdetails',
  ADD_UPDATE_COMMENT: '/api/TicketToolManagement/addupdatecomment',
  DELETE_COMMENT: '/api/TicketToolManagement/deletecommentdetails',
  DELETE_TOOL_ATTACHMENT: '/api/TicketToolManagement/deletetickettoolattachmentdetail',
  ADD_ASSIGNEE_STATUS: '/api/TicketToolManagement/addassigneestatus',
  DATA_DEPENDENCY_ADD: '/api/TicketToolManagement/addupdateteam',
  DATA_DEPENDENCY_STATUS: '/api/TicketToolManagement/addupdateteamstatus',

  /** SUBSCRIPTION/PACKAGE */
  GET_ALL_PACKAGES: '/api/TicketManagement/package-details',
  ADD_PACKAGE: '/api/TicketManagement/add-packages',
  UPDATE_PACKAGE: '/api/TicketManagement/update-packages',
  DELETE_PACKAGE: '/api/TicketManagement/delete_packages?id=',

  /** NOTIFICATION */
  GET_RECENT_NOTIFICATION: '/api/TicketManagement/getnotification',
  UPDATE_NOTIFICATION: '/api/TicketManagement/updatenotification'

};
