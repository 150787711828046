import { combineReducers } from 'redux';

import authSlice from './slice/auth';
import toastSlice from './slice/toast';
import kanbanSlice from './slice/kanban';
import masterSlice from './slice/master';
import teamSlice from './slice/team';
import customerSlice from './slice/customer';
import customerFilterSlice from './slice/customerFilter';
import productsSlice from './slice/products';
import ticketManagementSlice from './slice/ticketManagement';
import subscriptionSlice from './slice/subscription';
import notificationSlice from './slice/notification';
export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  toast: toastSlice.reducer,
  kanban: kanbanSlice.reducer,
  master: masterSlice.reducer,
  team: teamSlice.reducer,
  products: productsSlice.reducer,
  customer: customerSlice.reducer,
  customerFilter: customerFilterSlice.reducer,
  ticketManagement: ticketManagementSlice.reducer,
  subscription: subscriptionSlice.reducer,
  notification: notificationSlice.reducer
});
